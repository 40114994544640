import React from 'react';

export default function ConfirmationPage() {
  return (
    <div>
      <div>
        Successfully booked!
      </div>
      <ul>
        <li>Room Price: $214</li>
        <li>Tax: $20</li>
        <li>Cleaning Fee: $17</li>
        <li>Total: $251</li>
      </ul>
    </div>
  )
}