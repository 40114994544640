import React from 'react';
import {
  useNavigate,
} from 'react-router-dom';

export default function RoomOptions() {

  const navigate = useNavigate();
  
  return (
  <div>
    <div>
      <div>
        room 1
      </div>
      <button onClick={() => navigate('/confirmationPage')}>
        book now
      </button>
    </div>
    <div>
      <div>
        room 2
      </div>
      <button onClick={() => navigate('/confirmationPage')}>
        book now
      </button>
    </div>
  </div>
  );
}