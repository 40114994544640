import {
  Routes,
  Route,
  BrowserRouter,
} from 'react-router-dom';
import RoomOptions from './RoomOptions';
import './App.css';
import ConfirmationPage from './ConfirmationPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
            path="*"
            element={(
              <RoomOptions />
            )}
            />
            <Route
              path="/confirmationPage"
              element={(
                <ConfirmationPage />
              )}
          />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
